import React, { useState } from "react";
import styles from "./css/Network.module.scss";
import AOS from "aos";
import "aos/dist/aos.css";

const Network = () => {
  AOS.init();

  const [area, setArea] = useState("서울");

  return (
    <div className={styles.container}>
      <div className={styles.greeting_top_wrapper}>
        <img
          src="../imgs/img/greeting-top.png"
          alt=""
          className={styles.greeting_top_img}
        />
      </div>
      <div className={styles.section}>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.network_top_wrapper}
        >
          <img
            src="../imgs/img/network-pin-big.svg"
            alt=""
            className={styles.network_top_icon}
          />
          <div className={styles.network_top_text}>
            신원의료재단 전국네트워크 영업소 위치안내
          </div>
        </div>
        <div className={styles.network_content_wrapper}>
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            className={styles.network_map_wrapper}
          >
            <div className={styles.network_map_sticky}>
              <div
                onClick={() => setArea("서울")}
                className={styles.pin_seoul + " " + styles.pin}
              />
              <div
                onClick={() => setArea("강원")}
                className={styles.pin_gangwon + " " + styles.pin}
              />
              <div
                onClick={() => setArea("인천")}
                className={styles.pin_incheon + " " + styles.pin}
              />
              <div
                onClick={() => setArea("경기")}
                className={styles.pin_gyeonggi + " " + styles.pin}
              />
              <div
                onClick={() => setArea("충남")}
                className={styles.pin_chungnam + " " + styles.pin}
              />
              <div
                onClick={() => setArea("충북")}
                className={styles.pin_chungbuk + " " + styles.pin}
              />
              <div
                onClick={() => setArea("대전")}
                className={styles.pin_daejeon + " " + styles.pin}
              />
              <div
                onClick={() => setArea("경북")}
                className={styles.pin_gyeongbuk + " " + styles.pin}
              />
              <div
                onClick={() => setArea("대구")}
                className={styles.pin_daegu + " " + styles.pin}
              />
              <div
                onClick={() => setArea("울산")}
                className={styles.pin_ulsan + " " + styles.pin}
              />
              <div
                onClick={() => setArea("부산")}
                className={styles.pin_busan + " " + styles.pin}
              />
              <div
                onClick={() => setArea("전남")}
                className={styles.pin_jeonnam + " " + styles.pin}
              />
              <div
                onClick={() => setArea("전북")}
                className={styles.pin_jeonbuk + " " + styles.pin}
              />
              <div
                onClick={() => setArea("광주")}
                className={styles.pin_gwangju + " " + styles.pin}
              />
              <div
                onClick={() => setArea("경남")}
                className={styles.pin_gyeongnam + " " + styles.pin}
              />
              <div
                onClick={() => setArea("제주")}
                className={styles.pin_jeju + " " + styles.pin}
              />
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            className={styles.network_area_wrapper}
          >
            <div className={styles.network_area_title_wrapper}>
              <div className={styles.network_area_title}>{area}지역</div>
              <div className={styles.network_area_subtitle}>
                {area}지역 네트워크 위치입니다.
                <br />
                지역 영업소의 위치/연락처를 알려드리고 있습니다.
              </div>
            </div>
            {area === "서울" ? (
              <Seoul />
            ) : area === "경기" ? (
              <Gyeonggi />
            ) : area === "인천" ? (
              <Incheon />
            ) : area === "부산" ? (
              <Busan />
            ) : area === "경남" ? (
              <Gyeongnam />
            ) : area === "울산" ? (
              <Ulsan />
            ) : area === "대구" ? (
              <Daegu />
            ) : area === "경북" ? (
              <Gyeongbuk />
            ) : area === "대전" ? (
              <Daejeon />
            ) : area === "충북" ? (
              <Chungbuk />
            ) : area === "충남" ? (
              <Chungnam />
            ) : area === "강원" ? (
              <Gangwon />
            ) : area === "전북" ? (
              <Jeonbuk />
            ) : area === "전남" ? (
              <Jeonnam />
            ) : area === "광주" ? (
              <Gwangju />
            ) : area === "제주" ? (
              <Jeju />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Network;

const Seoul = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;강남영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 031-751-1815, 031-751-1840 팩스 031-751-1816
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>06376</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            경기도 성남시 수정구 위례서일로 30 307호
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>서울 전 지역</span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;강북1영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 02-936-3330 팩스 02-936-9397
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>01905</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            서울시 노원구 월계동 321-2번지 희성프라자 213호
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>
            서울 강북 지역, 의정부시, 포천시, 남양주시, 구리시
          </span>
        </div>
      </div>

      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;강북2영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 02-971-0010 팩스 02-972-0130
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>01905</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            서울시 노원구 월계동 321-2번지 희성프라자 302호
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>서울 전 지역</span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;강북3영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 02-3296-6333 팩스 02-3296-6335
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>01905</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            서울시 노원구 월계동 321-2번지 희성프라자 408호
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>
            동대문구, 중랑구, 성동구, 광진구일부, 노원구일부, 남양주시, 구리시
          </span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;동부영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 031-791-8710 팩스 031-794-8712
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>12939</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            경기도 하남시 미사강변중앙로 11, 5층
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>
            서울 남동부 지역, 하남시, 성남시, 수원시
          </span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;서부영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 02-6401-8330 팩스 02-6401-7550
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>07548</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            서울시 강서구 양천로 510 현대프린스텔 208호
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>
            서울 남서부 지역
          </span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;중부영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 02-376-9031 팩스 02-376-9033
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>10541</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            경기도 고양시 덕양구 현천동 107-2, 203,4호
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>
            서울 북서부 지역, 고양시
          </span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;남부영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 02-3487-5071 팩스 02-3487-5070
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>06713</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            서울시 서초구 서초동 1467-1 한빛빌딩 105호
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>서울 전 지역</span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;이화영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 02-3462-2233, 팩스 02-3462-4834
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>06312</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            서울시 강남구 논현로 52(남현빌딩) 5층
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>
            서울 동작구,강남구,서초구,강동구
          </span>
        </div>
      </div>
    </div>
  );
};

const Gyeonggi = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;사업본부
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 1899-1510 팩스 02-801-5100
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>14316</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            경기도 광명시 소하로 81 아이플렉스 202호
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>경기 전 지역</span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;경기1영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 031-213-8601 팩스 031-213-8604
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>16226</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            경기도 수원시 영통구 이의동 1277-1번지,1층
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>경기 전 지역</span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;경기2영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 031-373-8090 팩스 031-373-8091
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>16226</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            경기도 화성시 동탄대로 21길 10 더퍼스트타워1차 410호 (영천동 652-5)
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>경기 전 지역</span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;광명영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 02-899-7080 팩스 02-899-7078
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>14316</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            경기도 광명시 소하로 109번길 14 신한빌딩 602호
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>경기 전 지역</span>
        </div>
      </div>
    </div>
  );
};

const Incheon = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;경인영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 032-321-5851 팩스 032-321-5855
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>22148</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            인천 미추홀구 경인로 372 아인에비뉴 지하2층 201동 B2140~B2145호
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>
            부천시, 김포시, 인천 일부
          </span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;인천1영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 032-465-7621 팩스 032-465-7623
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>22148</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            인천 미추홀구 경인로 372 아인에비뉴 지하2층 201동 B2140~B2145호
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>인천 전 지역</span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;인천2영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 031-434-8828 팩스 031-434-8830
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}></span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            경기 시흥시 서울대학로278번길 19-13 A-249호
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>인천 전 지역</span>
        </div>
      </div>
    </div>
  );
};

const Busan = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;부산영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 051-583-2491 팩스 051-583-2493
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>46214</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            부산광역시 연제구 연산동 104-52번지 2층
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>부산 전 지역</span>
        </div>
      </div>
    </div>
  );
};

const Gyeongnam = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;부산영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 051-583-2491 팩스 051-583-2493
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>46214</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            부산광역시 연제구 연산동 104-52번지 2층
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>경남 전 지역</span>
        </div>
      </div>
    </div>
  );
};

const Ulsan = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;부산영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 051-583-2491 팩스 051-583-2493
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>46214</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            부산광역시 연제구 연산동 104-52번지 2층
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>울산 전 지역</span>
        </div>
      </div>
    </div>
  );
};

const Daegu = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;대구영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 053-745-9395 팩스 053-745-9396
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>41250</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            대구시 동구 동부로 22길 79 5층
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>대구 전 지역</span>
        </div>
      </div>
    </div>
  );
};

const Gyeongbuk = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;대구영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 053-745-9395 팩스 053-745-9396
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>41250</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            대구시 동구 동부로 22길 79 5층
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>경북 전 지역</span>
        </div>
      </div>
    </div>
  );
};

const Daejeon = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;대전영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 042-485-6001 팩스 042-483-9001
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>35209</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            대전시 중구 계룡로 760 4층
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>대전 전 지역</span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;세종영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 042-485-6001 팩스 042-483-9001
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>35209</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            대전시 중구 계룡로 760 4층
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>대전 전 지역</span>
        </div>
      </div>
    </div>
  );
};

const Chungbuk = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;충주영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 043-853-4701 팩스 043-843-4701
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>27356</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            충청북도 충주시 연수동 1280번지 1층
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>충주 전 지역</span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;청주영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 043-241-2441 팩스 043-234-2442
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>28443</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            충청북도 청주시 흥덕구 대신로 132(송정로) 청주산업단지비즈니스센터
            502호
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>청주 전 지역</span>
        </div>
      </div>
    </div>
  );
};

const Chungnam = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;천안영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 041-622-6855 팩스 041-622-6956
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>31163</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            충남 천안시 서북구 검은들3길 46 미래시티빌딩 5층
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>충남 전 지역</span>
        </div>
      </div>
    </div>
  );
};

const Gangwon = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;원주영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 033-737-0131 팩스 033-737-0133
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>26434</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            강원도 원주시 단구로 69번길 15 1층
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>강원 전 지역</span>
        </div>
      </div>
    </div>
  );
};

const Jeonbuk = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;전북영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            사무실 063-905-5588 팩스 063-905-5591
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>55121</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            전라북도 전주시 완산구 평화동 1가 713-6, 2충
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>전북 전 지역</span>
        </div>
      </div>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;광주영업소
        </div>
        <div className={styles.network_area_content_bold}>
          연락처:
          <span className={styles.network_area_content_text}>
            062-373-8991(사무실), 062-373-8992(팩스)
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          우편번호:
          <span className={styles.network_area_content_text}>61992</span>
        </div>
        <div className={styles.network_area_content_bold}>
          주소:
          <span className={styles.network_area_content_text}>
            광주광역시 서구 화정로 53 , 2층
          </span>
        </div>
        <div className={styles.network_area_content_bold}>
          담당지역:
          <span className={styles.network_area_content_text}>
            광주,나주,장성,순천,여수(전라남도지역)
          </span>
        </div>
      </div>
    </div>
  );
};

const Gwangju = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;지역영업소 준비중입니다.
        </div>
      </div>
    </div>
  );
};

const Jeonnam = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;지역영업소 준비중입니다.
        </div>
      </div>
    </div>
  );
};

const Jeju = () => {
  return (
    <div className={styles.network_area_contents}>
      <div className={styles.network_area_content}>
        <div className={styles.network_area_content_title}>
          &nbsp;&nbsp;지역영업소 준비중입니다.
        </div>
      </div>
    </div>
  );
};
