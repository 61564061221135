import React, { useState } from "react";
import styles from "./css/Doctor.module.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import classNames from "classnames";

const Doctor = () => {
  AOS.init();
  const [num, setNum] = useState(1);

  const data1 = [
    require("./css/img/doctor_set_1/d1.png"),
    require("./css/img/doctor_set_1/d2.png"),
    require("./css/img/doctor_set_1/d3.png"),
    require("./css/img/doctor_set_1/d4.png"),
    require("./css/img/doctor_set_1/d5.png"),
    require("./css/img/doctor_set_1/d6.png"),
    require("./css/img/doctor_set_1/d7.png"),
    require("./css/img/doctor_set_1/d8.png"),
    require("./css/img/doctor_set_1/d9.png"),
    require("./css/img/doctor_set_1/d10.png"),
    require("./css/img/doctor_set_1/d11.png"),
    require("./css/img/doctor_set_1/d12.png"),
    require("./css/img/doctor_set_1/d13.png"),
    require("./css/img/doctor_set_1/d14.png"),
  ];

  const data2 = [
    require("./css/img/doctor_set_2/d1.png"),
    require("./css/img/doctor_set_2/d2.png"),
    require("./css/img/doctor_set_2/d3.png"),
    require("./css/img/doctor_set_2/d4.png"),
    require("./css/img/doctor_set_2/d5.png"),
    require("./css/img/doctor_set_2/d6.png"),
    require("./css/img/doctor_set_2/d7.png"),
    require("./css/img/doctor_set_2/d8.png"),
    require("./css/img/doctor_set_2/d9.png"),
    require("./css/img/doctor_set_2/d10.png"),
    require("./css/img/doctor_set_2/d11.png"),
    require("./css/img/doctor_set_2/d12.png"),
    require("./css/img/doctor_set_2/d13.png"),
    require("./css/img/doctor_set_2/d14.png"),
    require("./css/img/doctor_set_2/d15.png"),
    require("./css/img/doctor_set_2/d16.png"),
    require("./css/img/doctor_set_2/d17.png"),
  ];

  return (
    <div className={styles.container}>
      <div className={styles.greeting_top_wrapper}>
        <img
          src="../imgs/img/greeting-top.png"
          alt=""
          className={styles.greeting_top_img}
        />
      </div>

      <div className={styles.section}>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.greeting_title}
        >
          전문의 소개
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.doctor_subtitle}
        >
          신원의료재단의 전문의료진입니다.
        </div>
        <div
          className={styles.mission_top_wrapper}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div
            onClick={() => setNum(1)}
            className={
              num === 1
                ? classNames(
                    styles.mission_top_content,
                    styles.mission_top_selected
                  )
                : styles.mission_top_content
            }
          >
            진단검사
          </div>
          <div
            onClick={() => setNum(2)}
            className={
              num === 2
                ? classNames(
                    styles.mission_top_content,
                    styles.mission_top_selected
                  )
                : styles.mission_top_content
            }
          >
            병리검사
          </div>
        </div>
        {num === 1
          ? data1.map((data, i) => (
              <img
                src={data}
                key={i}
                alt="doctor"
                className={styles.doctor_img}
                data-aos="fade-up"
                data-aos-duration="2000"
              />
            ))
          : data2.map((data, i) => (
              <img
                src={data}
                key={i}
                alt="doctor"
                className={styles.doctor_img}
                data-aos="fade-up"
                data-aos-duration="2000"
              />
            ))}
      </div>
    </div>
  );
};

export default Doctor;
